import React, { useState } from 'react'
import '@ant-design/compatible/assets/index.css'
import { Modal, Input, Select, Typography, notification, Button } from 'antd'
import { getAuth } from '@misc'
import axios from 'axios'

const { TextArea } = Input

const ENDPOINT = process.env.REACT_APP_API_URL

function AddHotelBlackList({ isModalVisible, setIsModalVisible, getListBlockHotels }) {
    const [api, contextHolder] = notification.useNotification()

    const openNotificationWithIcon = (type, description) => {
        api[type]({
            message: 'Add hotel to blacklist',
            description
        })
    }

    const auth = getAuth()
    const [hotelIdSearch, setHotelIdSearch] = useState('')
    const [reason, setReason] = useState('')
    const [suppliers, setSuppliers] = useState([])
    const [isHotel, setIsHotel] = useState(false)
    const [listSuppliers, setListSuppliers] = useState([])
    const handleChange = value => {
        setSuppliers(value)
    }

    const handleModalOk = async () => {
        axios({
            url: `${ENDPOINT}/hotel/hotels/block-hotel`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: { hotelId: hotelIdSearch, blockReason: reason, suppliers }
        })
            .then(res => {
                getListBlockHotels(0)
                setIsModalVisible(false)
                setHotelIdSearch('')
                setReason('')
                setSuppliers([])
                setListSuppliers([])
                setIsHotel(false)
                if (res?.data?.success) {
                    openNotificationWithIcon('success', 'Add hotel to blacklist successfully')
                } else {
                    openNotificationWithIcon('error', res?.data?.error || 'Add hotel to blacklist unsuccessfully')
                }
            })
            .catch(err => {
                openNotificationWithIcon('error', err?.message || 'Add hotel to blacklist unsuccessfully')
            })
    }

    const handleModalCancel = () => {
        setHotelIdSearch('')
        setReason('')
        setSuppliers([])
        setListSuppliers([])
        setIsModalVisible(false)
        setIsHotel(false)
    }

    const handleSetValueReason = e => {
        setReason(e.target.value)
    }

    const handleSearchHotel = async () => {
        const res = await axios({
            url: `${ENDPOINT}/hotel/mappings/find`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: { ids: [hotelIdSearch], from: 'GoQuo' }
        })
        if (!res?.data?.length) {
            openNotificationWithIcon('error', 'Hotel not found!')
        } else {
            const values = res.data?.map(i => ({
                label: i.supplier_code,
                value: i.supplier_code
            }))

            setListSuppliers(values)
            setIsHotel(true)
        }
    }

    return (
        <div>
            {contextHolder}
            <Modal
                title="Add Hotel To Blacklist"
                visible={isModalVisible}
                onOk={handleModalOk}
                okButtonProps={{ disabled: !reason || !hotelIdSearch }}
                onCancel={handleModalCancel}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                        placeholder="Hotel Id"
                        value={hotelIdSearch}
                        onChange={e => setHotelIdSearch(e.target.value)}
                    />
                    <Button type="primary" onClick={handleSearchHotel} disabled={!hotelIdSearch}>
                        Search
                    </Button>
                </div>
                {isHotel ? (
                    <div>
                        <div style={{ margin: '16px 0' }}>
                            <TextArea
                                rows={4}
                                value={reason}
                                onChange={handleSetValueReason}
                                placeholder="BlackList Reason"
                            />
                        </div>
                        <div>
                            <Typography>Suppliers</Typography>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={handleChange}
                                options={listSuppliers}
                            />
                        </div>
                    </div>
                ) : null}
            </Modal>
        </div>
    )
}

export default AddHotelBlackList
