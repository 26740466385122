import React from 'react'
import {
    ApiOutlined,
    BarChartOutlined,
    CarOutlined,
    ClusterOutlined,
    CompassOutlined,
    CreditCardOutlined,
    DashboardOutlined,
    DesktopOutlined,
    DingdingOutlined,
    DollarOutlined,
    EllipsisOutlined,
    GoogleOutlined,
    SafetyCertificateOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    TeamOutlined,
    UserAddOutlined,
    AmazonOutlined,
    // StopOutlined,
    BranchesOutlined,
    AlertOutlined
} from '@ant-design/icons'
import Dashboard from '../views/dashboard/dashboard.jsx'

// flight management components
import FlightUser from '../views/flight/user.jsx'
import FlightAirline from '../views/flight/airline.jsx'
import FlightSupplier from '../views/flight/supplier.jsx'
import FlightBooking from '../views/flight/booking.jsx'
import { FlightPriceCompare } from '@flight'
import { GoogleCloudLogs } from '@gcloud-logs'
import { AwsLogs } from '../@aws-logs/index.js'

// car management components
import CarUser from '../views/car/user.jsx'
import CarSupplier from '../views/car/supplier.jsx'
import CarBooking from '../views/car/booking.jsx'

// hotel management components
import HotelUser from '../views/hotel/user.jsx'
import HotelSupplier from '../views/hotel/supplier.jsx'
import Taxes from '../views/hotel/taxes'
import HotelBooking from '../views/hotel/booking.jsx'
import Chart from '../views/hotel/chart.jsx'
import { HotelRebook } from '@hotel-rebook'
import { RegionMapping, HotelMapping, AmenitiesMapping, RemoveDuplicateHotelMapping } from '@mapping'

import HotelBlackList from '../views/hotel/blackList.jsx'
// management dashboard
import Currency from '../views/management/currency'
import { UserPage } from '@user'
import { RolePage } from '@role'

import { MappingPage } from '@tour-mapping'

import { CardPage } from '@card'
import { IconTaxes } from '../@icons/index.js'

import TourSupplier from '../views/tour/supplier.jsx'
// tour mangament components
import TourUser from '../views/tour/user.jsx'
import { AlertSettingPage } from '../@alert-setting'

const ThemeRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: <DashboardOutlined />,
        component: Dashboard,
        resource: 'dashboard',
        perform: 'view',
        ruotes: []
    },
    {
        navlabel: true,
        name: 'Products',
        icon: <EllipsisOutlined />,
        resources: ['hotel', 'flight', 'car'],
        perform: 'view'
    },
    {
        collapse: true,
        path: '/hotel',
        name: 'Hotel',
        state: 'hotel',
        icon: <ShopOutlined />,
        resource: 'hotel',
        perform: 'view',
        routes: [
            {
                path: '/hotel/users',
                name: 'User',
                icon: <UserAddOutlined />,
                component: HotelUser,
                resource: 'hotel-user',
                perform: 'view'
            },
            {
                path: '/hotel/black-list',
                name: 'Hotel Black List',
                icon: <ShopOutlined />,
                component: HotelBlackList,
                resource: 'hotel',
                perform: 'view'
            },
            {
                path: '/hotel/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: HotelSupplier,
                resource: 'hotel-supplier',
                perform: 'view'
            },
            {
                path: '/hotel/taxes-management',
                name: 'Taxes Management',
                icon: (
                    <span role="img" aria-label="api" className="anticon anticon-api">
                        <IconTaxes />
                    </span>
                ),
                component: Taxes,
                resource: 'hotel-taxes-management',
                perform: 'view'
            },
            {
                path: '/hotel/bookings',
                name: 'Booking Management',
                icon: <DesktopOutlined />,
                component: HotelBooking,
                resource: 'hotel-booking',
                perform: 'view'
            },
            {
                path: '/hotel/create-booking',
                name: 'Create Booking',
                mini: 'H',
                icon: <ShoppingCartOutlined />,
                component: HotelRebook,
                resource: 'hotel-create-booking',
                perform: 'view'
            },
            {
                path: '/hotel/charts',
                name: 'Chart',
                icon: <BarChartOutlined />,
                component: Chart,
                resource: 'hotel-chart',
                perform: 'view'
            },
            {
                collapse: true,
                path: '/hotel/mapping',
                name: 'Mapping',
                state: 'hotelMapping',
                icon: <ClusterOutlined />,
                resource: 'hotel-mapping',
                perform: 'view',
                routes: [
                    {
                        path: '/hotel/region-mapping',
                        name: 'Region Mapping',
                        icon: <CompassOutlined />,
                        component: RegionMapping,
                        resource: 'hotel-mapping',
                        perform: 'view'
                    },
                    {
                        path: '/hotel/hotel-mapping',
                        name: 'Hotel Mapping',
                        icon: <CompassOutlined />,
                        component: HotelMapping,
                        resource: 'hotel-mapping',
                        perform: 'view'
                    },
                    {
                        path: '/hotel/amenities-mapping',
                        name: 'Amenities Mapping',
                        icon: <CompassOutlined />,
                        component: AmenitiesMapping,
                        resource: 'hotel-mapping',
                        perform: 'view'
                    },
                    {
                        path: '/hotel/remove-duplicate-hotels-mapping',
                        name: 'Remove Duplicate Hotels',
                        icon: <CompassOutlined />,
                        component: RemoveDuplicateHotelMapping,
                        resource: 'hotel-mapping',
                        perform: 'view'
                    }
                ]
            }
        ]
    },

    {
        collapse: true,
        path: '/tour',
        name: 'Tour',
        state: 'tour',
        icon: <ShopOutlined />,
        resource: 'tour',
        perform: 'view',
        routes: [
            {
                path: '/tour/users',
                name: 'User',
                icon: <UserAddOutlined />,
                component: TourUser,
                resource: 'tour-user',
                perform: 'view'
            },
            {
                path: '/tour/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: TourSupplier,
                resource: 'tour-supplier',
                perform: 'view'
            }
        ]
    },
    {
        collapse: true,
        path: '/flight',
        name: 'Flight',
        state: 'flight',
        icon: <DingdingOutlined />,
        resource: 'flight',
        perform: 'view',
        routes: [
            {
                path: '/flight/users',
                name: 'User',
                icon: <UserAddOutlined />,
                component: FlightUser,
                resource: 'flight-user',
                perform: 'view'
            },
            {
                path: '/flight/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: FlightSupplier,
                resource: 'flight-supplier',
                perform: 'view'
            },
            {
                path: '/flight/airlines',
                name: 'Airline',
                icon: <BranchesOutlined />,
                component: FlightAirline,
                resource: 'flight-airline',
                perform: 'view'
            },
            {
                path: '/flight/bookings',
                name: 'Booking Management',
                icon: <DesktopOutlined />,
                component: FlightBooking,
                resource: 'flight-booking',
                perform: 'view'
            },
            {
                path: '/flight/price-comparison',
                name: 'Price Comparison',
                icon: <DollarOutlined />,
                component: FlightPriceCompare,
                resource: 'flight-price-comparison',
                perform: 'view'
            }
        ]
    },
    {
        collapse: true,
        path: '/car',
        name: 'Car',
        state: 'car',
        icon: <CarOutlined />,
        resource: 'car',
        perform: 'view',
        routes: [
            {
                path: '/car/users',
                name: 'User',
                icon: <UserAddOutlined />,
                component: CarUser,
                resource: 'car-user',
                perform: 'view'
            },
            {
                path: '/car/suppliers',
                name: 'Supplier',
                icon: <ApiOutlined />,
                component: CarSupplier,
                resource: 'car-supplier',
                perform: 'view'
            },
            {
                path: '/car/bookings',
                name: 'Booking Management',
                icon: <DesktopOutlined />,
                component: CarBooking,
                resource: 'car-booking',
                perform: 'view'
            }
        ]
    },
    {
        collapse: false,
        path: '/logs/viewer',
        name: 'Logs Viewer',
        state: 'gcloud',
        icon: <GoogleOutlined />,
        component: GoogleCloudLogs,
        resource: 'log',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/logs/aws',
        name: 'Logs Aws',
        state: 'aws',
        icon: <AmazonOutlined />,
        component: AwsLogs,
        resource: 'log',
        perform: 'view'
    },
    {
        navlabel: true,
        name: 'Management',
        icon: <EllipsisOutlined />,
        resources: ['currency', 'user', 'role'],
        perform: 'view'
    },
    {
        collapse: false,
        path: '/currency-rates',
        name: 'Currency Rates',
        state: 'currency-rates',
        icon: <DollarOutlined />,
        component: Currency,
        resource: 'currency',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/users',
        name: 'Users',
        state: 'users',
        icon: <TeamOutlined />,
        component: UserPage,
        resource: 'user',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/roles',
        name: 'Roles',
        state: 'roles',
        icon: <SafetyCertificateOutlined />,
        component: RolePage,
        resource: 'role',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/tour-mapping',
        name: 'Tour Mapping',
        state: 'roles',
        icon: <ClusterOutlined />,
        component: MappingPage,
        resource: 'role',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/virtual-card',
        name: 'Virtual Card',
        state: 'roles',
        icon: <CreditCardOutlined />,
        component: CardPage,
        resource: 'role',
        perform: 'view'
    },
    {
        collapse: false,
        path: '/alert-setting',
        name: 'Alert Setting',
        state: 'roles',
        icon: <AlertOutlined />,
        component: AlertSettingPage,
        resource: 'role',
        perform: 'view'
    }
]

export default ThemeRoutes
