/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Input, Col, Row, notification, Table, Button, Form as Form_Editable, Popconfirm } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import SupplierList from '../../components/SupplierList'
import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'

const PRODUCT = 'flight'

const columns = [
    {
        key: 'code',
        dataIndex: 'code',
        title: 'Code',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.code.localeCompare(b.code)
    },
    {
        key: 'source_id',
        dataIndex: 'source_id',
        title: 'Source ID',
        editable: false
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        editable: true
    },
    {
        key: 'airline_code',
        dataIndex: 'airline_code',
        title: 'Airline Code',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.airline_code.localeCompare(b.airline_code)
    }
]

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
    const [form] = Form_Editable.useForm()
    return (
        <Form_Editable form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form_Editable>
    )
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            inputRef.current.focus()
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex]: record[dataIndex]
        })
    }

    const save = async () => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleSave({ ...record, ...values })
        } catch (errInfo) {
            console.log('Save failed:', errInfo)
        }
    }

    let childNode = children

    if (editable) {
        childNode = editing ? (
            <Form_Editable.Item
                style={{
                    margin: 0
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`
                    }
                ]}>
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form_Editable.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24
                }}
                onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

function FlightSupplier({ form }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`
    const [suppliers, setSuppliers] = useState([])
    const [currentSupplier, setCurrentSupplier] = useState({})
    const [customConfigs, setCustomConfigs] = useState([])
    const [formState, _setFormState] = useState('HIDE')
    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getSuppliers = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/suppliers`,
            headers
        })
            .then(({ data }) => {
                setSuppliers(data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    const columnCustomConfig = [
        {
            key: 'key',
            dataIndex: 'key',
            title: 'Key',
            editable: true
        },
        {
            key: 'value',
            dataIndex: 'value',
            title: 'Value',
            editable: true,
            render: text =>
                text.length > 20 ? (
                    <span title={text}>{`${text.substring(0, 30)} ...`}</span>
                ) : (
                    <span title={text}>{text}</span>
                )
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                customConfigs.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null
        }
    ]

    const parseJSON = str => {
        try {
            return JSON.parse(str)
        } catch (e) {
            return str
        }
    }

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                const custom_config = customConfigs.reduce(function(result, item) {
                    result[item.key] = parseJSON(item.value)
                    return result
                }, {})
                values.custom_config = JSON.stringify(custom_config)

                let options = {
                    url: `${apiUrl}/suppliers`,
                    headers,
                    data: values
                }

                switch (supplierForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        options.data = values
                        break
                    case 'UPDATE': {
                        const changedFields = {}

                        Object.keys(values).forEach(prop => {
                            if (values[prop] !== undefined && values[prop] !== currentSupplier[prop]) {
                                changedFields[prop] = values[prop]
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "Supplier infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'put'
                        options.url += `/${currentSupplier.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description: (formState === 'CREATE' ? 'Created' : 'Updated') + ' supplier: ' + values.code
                        })
                        getSuppliers()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'name',
            'code',
            'source_id',
            'airline_code',
            'url',
            'username',
            'passwd',
            'role_code',
            'channel',
            'agency_code',
            'default_currency'
        ]

        setCustomConfigs([])

        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
            if (values.custom_config) {
                const key_custom_config = Object.keys(values.custom_config)
                const lst_customConfigs = []
                key_custom_config.map(item => {
                    const newData = {
                        key: item,
                        value:
                            typeof values.custom_config[item] === 'object'
                                ? JSON.stringify(values.custom_config[item])
                                : values.custom_config[item]
                    }
                    lst_customConfigs.push(newData)
                })

                setCustomConfigs(lst_customConfigs)
            }
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
        setCurrentSupplier(values)
    }

    const handleDelete = key => {
        const dataSource = [...customConfigs]
        setCustomConfigs(dataSource.filter(item => item.key !== key))
    }

    const handleAdd = () => {
        const newData = {
            key: 'key',
            value: 'value'
        }
        const existKey = customConfigs.find(x => x.key == newData.key)
        if (!existKey) {
            setCustomConfigs([...customConfigs, newData])
        }
    }

    const handleSave = row => {
        const newData = [...customConfigs]
        const index = newData.findIndex(item => row.key === item.key)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setCustomConfigs(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    }

    const _columnCustomConfig = columnCustomConfig.map(col => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave
            })
        }
    })

    return (
        <div>
            <Card>
                <SupplierList
                    suppliers={suppliers}
                    product={PRODUCT}
                    columns={columns}
                    getSuppliers={getSuppliers}
                    setFormState={setFormState}
                />
                <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Code" hasFeedback>
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                pattern: new RegExp(/^[a-zA-Z0-9_]*$/),
                                                message: 'Please enter a valid Source ID'
                                            },
                                            { required: true, message: 'Please enter supplier code!' }
                                        ]
                                    })(<Input disabled={formState === 'UPDATE'} />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Name" hasFeedback>
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Source ID" hasFeedback>
                                    {getFieldDecorator('source_id', {
                                        rules: [
                                            {
                                                pattern: new RegExp(/^[a-zA-Z0-9_]*$/),
                                                message: 'Please enter a valid Source ID'
                                            },
                                            { required: true, message: 'Please enter Source ID!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}></Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Airline Code" hasFeedback>
                                    {getFieldDecorator('airline_code', {
                                        rules: [
                                            {
                                                type: 'string',
                                                max: 2
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Url" hasFeedback>
                                    {getFieldDecorator('url', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Username" hasFeedback>
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Password" hasFeedback>
                                    {getFieldDecorator('passwd', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Role Code" hasFeedback>
                                    {getFieldDecorator('role_code', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Channel" hasFeedback>
                                    {getFieldDecorator('channel', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Agency Code" hasFeedback>
                                    {getFieldDecorator('agency_code', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Default Currency" hasFeedback>
                                    {getFieldDecorator('default_currency', {
                                        rules: [
                                            {
                                                type: 'string',
                                                max: 3
                                            }
                                        ]
                                    })(<Input maxLength={3} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                                    Add a row
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={24}>
                                <Table
                                    components={components}
                                    dataSource={customConfigs}
                                    columns={_columnCustomConfig}
                                    bordered
                                    rowClassName={() => 'editable-row'}
                                />
                            </Col>
                        </Row>
                    </Form>
                </FormDialog>
            </Card>
        </div>
    )
}

export default Form.create({ name: 'flight-supplier' })(FlightSupplier)
