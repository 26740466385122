/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import {
    notification,
    Card,
    Col,
    Row,
    Input,
    AutoComplete,
    Tag,
    Button,
    Table,
    Form as Form_Editable,
    Popconfirm,
    Collapse
} from 'antd'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import SupplierList from '../../components/SupplierList'
import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'

const { Panel } = Collapse
const PRODUCT = 'hotel'

const columns = [
    {
        key: 'code',
        dataIndex: 'code',
        title: 'Code',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.code.localeCompare(b.code)
    },
    {
        key: 'source_id',
        dataIndex: 'source_id',
        title: 'Source'
    },
    {
        key: 'contract_id',
        dataIndex: 'contract_id',
        title: 'Contract'
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    },
    {
        key: 'rate_type',
        dataIndex: 'rate_type',
        title: 'Rate Type',
        render: cell => {
            switch (cell) {
                case 'Package':
                    return <Tag color="green">{cell}</Tag>
                case 'Standalone':
                    return <Tag color="orange">{cell}</Tag>
                default:
                    return <Tag color="geekblue">{cell}</Tag>
            }
        }
    }
]

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
    const [form] = Form_Editable.useForm()
    return (
        <Form_Editable form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form_Editable>
    )
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            inputRef.current.focus()
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex]: record[dataIndex]
        })
    }

    const save = async () => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleSave({ ...record, ...values })
        } catch (errInfo) {
            console.log('Save failed:', errInfo)
        }
    }

    let childNode = children

    if (editable) {
        childNode = editing ? (
            <Form_Editable.Item
                style={{
                    margin: 0
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`
                    }
                ]}>
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form_Editable.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24
                }}
                onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

function HotelSupplier({ form }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`
    const [suppliers, setSuppliers] = useState([])
    const [currentSupplier, setCurrentSupplier] = useState({})
    const [customConfigs, setCustomConfigs] = useState([])
    const [changedFields, setChangedFields] = useState({})
    const [formState, _setFormState] = useState('HIDE')
    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getSuppliers = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/suppliers`,
            headers
        })
            .then(({ data }) => {
                setSuppliers(data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    const columnCustomConfig = [
        {
            key: 'key',
            dataIndex: 'key',
            title: 'Key',
            editable: true
        },
        {
            key: 'value',
            dataIndex: 'value',
            title: 'Value',
            editable: true
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                customConfigs.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null
        }
    ]

    const parseJSON = str => {
        try {
            return JSON.parse(str)
        } catch (e) {
            return str
        }
    }

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                const custom_config = customConfigs.reduce(function(result, item) {
                    result[item.key] = parseJSON(item.value)
                    return result
                }, {})
                values.custom_config = JSON.stringify(custom_config)

                let options = {
                    url: `${apiUrl}/suppliers`,
                    headers,
                    data: values
                }

                switch (supplierForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        options.data = values
                        break
                    case 'UPDATE': {
                        Object.keys(values).forEach(prop => {
                            if (values[prop] !== undefined && values[prop] !== currentSupplier[prop]) {
                                changedFields[prop] = values[prop]
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "Supplier infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'put'
                        options.url += `/${currentSupplier.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description: (formState === 'CREATE' ? 'Created' : 'Updated') + ' supplier: ' + values.code
                        })
                        getSuppliers()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator, setFieldsValue } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'name',
            'code',
            'source_id',
            'contract_id',
            'username',
            'password',
            'email',
            'client_id',
            'api_key',
            'shared_secret',
            'url',
            'business_type',
            'rate_type'
        ]

        setCustomConfigs([])

        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            setChangedFields({})
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
            if (values.custom_config) {
                const key_custom_config = Object.keys(values.custom_config)
                const lst_customConfigs = []
                key_custom_config.map(item => {
                    const newData = {
                        key: item,
                        value:
                            typeof values.custom_config[item] === 'object'
                                ? JSON.stringify(values.custom_config[item])
                                : values.custom_config[item]
                    }
                    lst_customConfigs.push(newData)
                })

                setCustomConfigs(lst_customConfigs)
            }
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
        setCurrentSupplier(values)
    }

    const handleDelete = key => {
        const dataSource = [...customConfigs]
        setCustomConfigs(dataSource.filter(item => item.key !== key))
    }

    const handleAdd = () => {
        const newData = {
            key: 'key',
            value: 'value'
        }
        const existKey = customConfigs.find(x => x.key == newData.key)
        if (!existKey) {
            setCustomConfigs([...customConfigs, newData])
        }
    }

    const handleSave = row => {
        const newData = [...customConfigs]
        const index = newData.findIndex(item => row.key === item.key)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setCustomConfigs(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    }

    const _columnCustomConfig = columnCustomConfig.map(col => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave
            })
        }
    })

    const selectSupplier = code => {
        const supplier = suppliers.find(s => s.code === code)

        if (supplier) {
            setFieldsValue({
                source_id: supplier.source_id,
                url: supplier.url
            })
        } else {
            form.resetFields()
            setFieldsValue({
                source_id: Math.random()
                    .toString(36)
                    .substring(2, 15)
            })
        }

        if (formState === 'CREATE') {
            const latestContract = _.maxBy(suppliers, s => Number(s.contract_id))
            setFieldsValue({
                contract_id: (Number(latestContract.contract_id) + 1).toString()
            })
        }
    }

    return (
        <Card>
            <SupplierList
                suppliers={suppliers}
                product={PRODUCT}
                columns={columns}
                getSuppliers={getSuppliers}
                setFormState={setFormState}
            />
            <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                {supplierForm && supplierForm.current ? (
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Code" hasFeedback>
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Code is required!'
                                            },
                                            { required: true, message: 'Please enter supplier code!' }
                                        ]
                                    })(
                                        <AutoComplete
                                            dataSource={_.uniqBy(suppliers, 'code').map(s => s.code)}
                                            placeholder="Select Supplier"
                                            onChange={selectSupplier}
                                            disabled={formState === 'UPDATE'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Name" hasFeedback>
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Name is required!'
                                            },
                                            { required: true, message: 'Please enter supplier name!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Source" hasFeedback>
                                    {getFieldDecorator('source_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Source ID!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Contract" hasFeedback>
                                    {getFieldDecorator('contract_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Contact ID!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Username" hasFeedback>
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Username!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Password" hasFeedback>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Password!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Url" hasFeedback>
                                    {getFieldDecorator('url', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter URL!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Email" hasFeedback>
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                type: 'email'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter email!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Client Id" hasFeedback>
                                    {getFieldDecorator('client_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="API Key" hasFeedback>
                                    {getFieldDecorator('api_key', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Shared Secret" hasFeedback>
                                    {getFieldDecorator('shared_secret', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Rate Type" hasFeedback>
                                    {getFieldDecorator('rate_type', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Rate type is required!'
                                            },
                                            { required: true, message: 'Please select one rate type!' }
                                        ]
                                    })(<AutoComplete dataSource={['Combined', 'Package', 'Standalone']} />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Business Type" hasFeedback>
                                    {getFieldDecorator('business_type', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={4}>
                                <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16, height: '47px' }}>
                                    Add a row
                                </Button>
                            </Col>
                            <Col md={18}>
                                <Collapse style={{ marginBottom: 16 }}>
                                    <Panel header="Regular keys">
                                        <table cellSpacing="0" cellPadding="5" border="1">
                                            <tr>
                                                <td>use_cug_rate</td>
                                                <td>true | false</td>
                                                <td>Is CUG or not CUG</td>
                                            </tr>
                                            <tr>
                                                <td>is_onetime_search</td>
                                                <td>true | false</td>
                                                <td>Search multiple ids in one time or not, for searchbyid</td>
                                            </tr>
                                        </table>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={24}>
                                <Table
                                    components={components}
                                    dataSource={customConfigs}
                                    columns={_columnCustomConfig}
                                    bordered
                                    rowClassName={() => 'editable-row'}
                                />
                            </Col>
                        </Row>
                    </Form>
                ) : null}
            </FormDialog>
        </Card>
    )
}

export default Form.create({ name: 'hotel-supplier' })(HotelSupplier)
