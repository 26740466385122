import React from 'react'
import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { Button, Modal, Input, Table, Switch, Select } from 'antd'
import axios from 'axios'
import { notification } from 'antd'
import _ from 'lodash'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'
import { Can, Say, check } from '../can'

const tourApiUrl = `${appConfig.apiUrl}/tour`
const RESOURCE = 'tour-user'

class ManageUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headers: {},
            user_suppliers: [],
            suppliers: [],
            selectedSupplier: null,
            userMarkup: null,
            addSupplierModal: false,
            setMarkupModal: false,
            removeSupplierModal: false,
            switchModal: false
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
    }

    componentDidMount() {
        const { props } = this
        axios({
            method: 'get',
            url: `${tourApiUrl}/users/${props.match.params.id}/suppliers`,
            headers: this.state.headers
        })
            .then(({ data }) => {
                this.setState({ user_suppliers: data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )

        axios({
            method: 'get',
            url: `${tourApiUrl}/suppliers`,
            headers: this.state.headers
        })
            .then(({ data }) => {
                this.setState({ suppliers: data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    toggleAddSupplier = () => {
        this.setState({
            addSupplierModal: !this.state.addSupplierModal
        })
    }

    toggleSetMarkup = () => {
        this.setState({
            setMarkupModal: !this.state.setMarkupModal
        })
    }

    toggleRemoveSupplier = () => {
        this.setState({
            removeSupplierModal: !this.state.removeSupplierModal
        })
    }

    toggleSwitchModal = () => {
        this.setState({
            switchModal: !this.state.switchModal
        })
    }

    addSupplier = () => {
        const { props } = this
        axios({
            method: 'post',
            url: `${tourApiUrl}/users/${props.match.params.id}/suppliers`,
            headers: this.state.headers,
            data: {
                supplier_id: this.state.selectedSupplier
            }
        })
            .then(() => {
                this.toggleAddSupplier()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    removeSupplier = supplierId => {
        const { props } = this
        axios({
            method: 'delete',
            url: `${tourApiUrl}/users/${props.match.params.id}/suppliers/${supplierId}`,
            headers: this.state.headers
        })
            .then(() => {
                this.toggleRemoveSupplier()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    applyMarkup = () => {
        const { props } = this

        axios({
            method: 'patch',
            url: `${tourApiUrl}/users/${props.match.params.id}/suppliers`,
            headers: this.state.headers,
            data: {
                markup: this.state.userMarkup
            }
        })
            .then(() => {
                this.toggleSetMarkup()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    selectSupliers = () => {
        const existed = this.state.user_suppliers.map(u => u.id)

        return _(this.state.suppliers)
            .filter(s => !existed.includes(s.id))
            .orderBy('name')
            .map(user => (
                <Select.Option value={user.id} key={user.id}>
                    {user.code} - {user.name}
                </Select.Option>
            ))
            .value()
    }

    selectSupplier = selectedSupplier => {
        this.setState({ selectedSupplier })
    }

    changeMarkup = event => {
        this.setState({ userMarkup: event.target.value })
    }

    changeSupplierStatus = supplier => {
        const { props } = this
        axios({
            method: 'patch',
            url: `${tourApiUrl}/users/${props.match.params.id}/suppliers/${supplier.id}`,
            headers: this.state.headers,
            data: {
                supplier_code: supplier.code,
                active: !!supplier.inactive
            }
        })
            .then(() => {
                this.toggleSwitchModal()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    showConfirm = row => {
        Modal.confirm({
            title: `Are you sure to turn ${row.inactive ? 'on' : 'off'} ${row.code}?`,
            onOk: () => this.changeSupplierStatus(row),
            onCancel: () => this.toggleSwitchModal()
        })
    }

    statusCell = (cell, row) => (
        <Switch
            disabled={!check(RESOURCE, 'edit')}
            defaultChecked={!row.inactive}
            checked={!row.inactive}
            onClick={() => this.showConfirm(row)}
        />
    )

    showDeleteConfirm = row => {
        Modal.confirm({
            title: `Are you sure to remove ${row.code}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => this.removeSupplier(row.id),
            onCancel: () => this.toggleRemoveSupplier()
        })
    }

    actionCell = (cell, row) => (
        <Can resource={RESOURCE} perform="edit">
            <Say yes>
                <Button onClick={() => this.showDeleteConfirm(row)} type="danger">
                    <UserDeleteOutlined /> Remove
                </Button>
            </Say>
        </Can>
    )

    render() {
        const tableColumn = [
            {
                key: 'code',
                dataIndex: 'code',
                title: 'Supplier Code'
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: 'Supplier Name'
            },
            {
                key: 'inactive',
                dataIndex: 'inactive',
                title: 'Status',
                className: 'text-center',
                render: this.statusCell
            }
        ]

        if (check(RESOURCE, 'edit')) {
            tableColumn.push({
                key: 'dummy',
                dataIndex: 'dummy',
                title: 'Action',
                className: 'text-center',
                render: this.actionCell
            })
        }

        const { user_suppliers } = this.state

        return (
            <div>
                <div className="mb-3 row">
                    <Can resource={RESOURCE} perform="edit">
                        <Say yes>
                            <div className="col-md-7">
                                <Button type="primary" onClick={this.toggleAddSupplier}>
                                    <UserAddOutlined /> Add Supplier
                                </Button>{' '}
                            </div>
                        </Say>
                    </Can>
                </div>
                <br />
                <Table rowKey="id" bordered dataSource={user_suppliers} columns={tableColumn} />

                <Can resource={RESOURCE} perform="edit">
                    <Say yes>
                        {/* add supplier modal */}
                        <Modal
                            visible={this.state.addSupplierModal}
                            onClick={this.toggleAddSupplier}
                            title="Add Supplier"
                            onOk={this.addSupplier}
                            onCancel={this.toggleAddSupplier}>
                            Select supplier you want to add into this user
                            <Select
                                showSearch
                                filterOption
                                optionFilterProp="children"
                                onSelect={this.selectSupplier}
                                style={{ minWidth: '100%' }}>
                                {this.selectSupliers()}
                            </Select>
                        </Modal>
                        {/* set markup modal */}
                        <Modal
                            visible={this.state.setMarkupModal}
                            // onClick={this.toggleSetMarkup}
                            title="Markup Configuration"
                            onOk={this.changeMarkup}
                            onCancel={this.toggleSetMarkup}>
                            Enter the markup value will apply for all suppliers in this user
                            <Input type="number" onChange={this.changeMarkup} />
                        </Modal>
                    </Say>
                </Can>
            </div>
        )
    }
}

export default ManageUser
