import React, { useState, useEffect } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Table, Radio, Button, Modal, Card, Pagination } from 'antd'
import { getAuth } from '@misc'
import axios from 'axios'
import AddHotelBlackList from './addBlackList'

const ENDPOINT = process.env.REACT_APP_API_URL
const SIZE = 10

function HotelBlackList() {
    const auth = getAuth()
    const [totalHotelBlock, setTotalHotelBlock] = useState(9)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isModalVisibleConfirm, setIsModalVisibleConfirm] = useState(false)
    const [hotelBlocks, setHotelBlocks] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const [hotelId, setHotelId] = useState(null)

    const handleSelectHotel = key => {
        setHotelId(key)
    }

    useEffect(() => {
        getListBlockHotels(0)
    }, [])

    const handleChangePage = async page => {
        await getListBlockHotels(page - 1)
        setCurrentPage(page)
    }

    const getListBlockHotels = async page => {
        const res = await axios({
            url: `${ENDPOINT}/hotel/hotels/blocked-hotels?page=${page}&size=${SIZE}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...auth }
        })
        if (res?.data?.data) {
            setHotelBlocks(res.data.data)
            setTotalHotelBlock(res.data.total)
        }
    }
    const paginationConfig = {
        current: currentPage,
        pageSize: SIZE,
        total: totalHotelBlock,
        onChange: handleChangePage,
        showSizeChanger: false,
        pageSizeOptions: [10]
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'hotel_id',
            key: 'hotel_id'
        },
        {
            title: 'Hotel Name',
            dataIndex: 'hotel_name',
            key: 'hotel_name'
        },
        {
            title: 'Reason',
            dataIndex: 'block_reason',
            key: 'block_reason'
        },
        {
            title: 'Suppliers',
            dataIndex: 'suppliers',
            key: 'suppliers'
        },
        {
            title: 'Block Level',
            dataIndex: 'block_level',
            key: 'block_level'
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <Radio checked={hotelId === record.hotel_id} onChange={() => handleSelectHotel(record.hotel_id)} />
            )
        }
    ]

    const handleUnBlockHotel = async () => {
        await axios({
            url: `${ENDPOINT}/hotel/hotels/unblock-hotel`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: { hotelId }
        })

        await getListBlockHotels(0)
    }

    const handleAddToBlackList = () => {
        setIsModalVisible(true)
    }

    const handleOk = async () => {
        await handleUnBlockHotel()
        setIsModalVisibleConfirm(false)
    }

    const handleCancel = () => {
        setIsModalVisibleConfirm(false)
    }

    return (
        <div>
            <Card>
                <Button
                    type="primary"
                    onClick={() => setIsModalVisibleConfirm(true)}
                    style={{ marginBottom: 16, float: 'right' }}
                    disabled={!hotelId}>
                    Remove
                </Button>
                <Button type="primary" onClick={handleAddToBlackList} style={{ marginBottom: 16, float: 'left' }}>
                    Add to black list
                </Button>
                <Table columns={columns} dataSource={hotelBlocks} pagination={false} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Pagination {...paginationConfig} />
                </div>
            </Card>

            <AddHotelBlackList
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                getListBlockHotels={getListBlockHotels}
            />
            <Modal title="Remove" visible={isModalVisibleConfirm} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure?</p>
            </Modal>
        </div>
    )
}

export default Form.create({ name: 'hotel-black-list' })(HotelBlackList)
